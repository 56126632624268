import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './TiledList.module.scss';
import Tile from '../Tile/Tile';

function TiledList({ title, tiles, className, ...props }) {
    if (!tiles || tiles.length === 0) {
        return null;
    }

    return (
        <section className={classNames(styles.tiledList, className)} {...props}>
            {title && <h2 className={styles.header}>{title}</h2>}
            <ul className={styles.tiles}>
                {tiles.map((tile) => (
                    <Tile
                        key={tile.title}
                        className={styles.tile}
                        title={tile.title}
                        url={tile.url}
                        imgSrc={tile.imgSrc}
                    />
                ))}
            </ul>
        </section>
    );
}

TiledList.propTypes = {
    title: PropTypes.string,
    tiles: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            imgSrc: PropTypes.string.isRequired,
        })
    ).isRequired,
    className: PropTypes.string,
};

export default TiledList;
