import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandableSection from '../ExpandableSection/ExpandableSection';
import styles from './Content.module.scss';

function Content({ content, otherContent, className, ...props }) {
    if (!content) {
        return null;
    }

    return (
        <div className={classNames(styles.content, className)} {...props}>
            <section
                className={styles.mainContent}
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
            {otherContent &&
                otherContent.length > 0 &&
                otherContent.map((contentItem) => (
                    <ExpandableSection
                        key={contentItem.title}
                        title={contentItem.title}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: contentItem.content,
                            }}
                        ></div>
                    </ExpandableSection>
                ))}
        </div>
    );
}

Content.propTypes = {
    content: PropTypes.string.isRequired,
    otherContent: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        })
    ),
    className: PropTypes.string,
};

export default Content;
