export function isExternalUrl(url) {
    if (url[0] == '/') {
        return false;
    } else {
        return true;
    }
}

const urlService = { isExternalUrl };

export default urlService;
