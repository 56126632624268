import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ExpandableSection.module.scss';

function ExpandableSection({
    title,
    children,
    as: T = 'div',
    className,
    ...props
}) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <T
            className={classNames(
                styles.expandableSection,
                { [styles.isOpen]: isOpen },
                className
            )}
            {...props}
        >
            <h3 className={styles.header} onClick={() => setIsOpen(!isOpen)}>
                {title}
                <i className={styles.button} />
            </h3>
            <div className={styles.content}>{children}</div>
        </T>
    );
}

ExpandableSection.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default ExpandableSection;
