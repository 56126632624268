import PropTypes from 'prop-types';
import Faqs from '../components/Faqs/Faqs';
import Hero from '../components/Hero/Hero';
import PageMetadata from '../components/PageMetadata/PageMetadata';
import FullWidthTemplate from '../components/templates/FullWidthTemplate/FullWidthTemplate';
import Content from '../components/Content/Content';
import TiledList from '../components/TiledList/TiledList';
import { getHomePage } from '../services/contentService';
import styles from '../styles/pages/home.module.scss';

function Home({ data }) {
    return (
        <FullWidthTemplate className={styles.home}>
            <PageMetadata
                title={data.meta.title}
                description={data.meta.description}
                relativeUrl=""
                imageUrl={data.image}
            />

            <Hero
                heading={data.heading}
                subheading={data.subheading}
                imgSrc={data.image}
                hideHeaderBackground={true}
            />

            {data.content && (
                <Content className={styles.content} content={data.content} />
            )}

            <TiledList
                className={styles.links}
                title={data.links.header}
                tiles={data.links.links}
            />

            <Faqs
                className={styles.faqs}
                title="Frequently Asked Questions (FAQs)"
                questions={data.faqs}
            />
        </FullWidthTemplate>
    );
}

Home.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        subheading: PropTypes.string,
        image: PropTypes.string.isRequired,
        content: PropTypes.string,
        links: PropTypes.shape({
            header: PropTypes.string.isRequired,
            links: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    imgSrc: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                })
            ),
        }),
        meta: PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    }),
};

export async function getServerSideProps() {
    const data = await getHomePage();
    return { props: { data }, notFound: data.notFound };
}

export default Home;
