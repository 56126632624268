import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandableSection from '../ExpandableSection/ExpandableSection';
import styles from './Faqs.module.scss';
import FAQsPageData from '../structuredData/FAQsPageData/FAQsPageData';

function Faqs({ questions, title, className, ...props }) {
    if (!questions || questions.length === 0) {
        return null;
    }
    return (
        <>
            <FAQsPageData questions={questions} />
            <section className={classNames(styles.faqs, className)} {...props}>
                {title && <h2>{title}</h2>}

                {questions && questions.length > 0 && (
                    <ul className={styles.questions}>
                        {questions.map(({ question, answer }) => (
                            <ExpandableSection
                                key={question}
                                title={question}
                                as="li"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: answer,
                                    }}
                                />
                            </ExpandableSection>
                        ))}
                    </ul>
                )}
            </section>
        </>
    );
}

Faqs.propTypes = {
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string.isRequired,
            answer: PropTypes.string.isRequired,
        })
    ),
    title: PropTypes.string,
    className: PropTypes.string,
};

export default Faqs;
