import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

function FAQsPageData({ questions }) {
    if (!questions) {
        return null;
    }

    return (
        <Head>
            <script
                key="Faqs"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(getData(questions)),
                }}
            ></script>
        </Head>
    );
}

function getData(questions) {
    return {
        '@context': 'https://schema.org/',
        '@type': 'FAQPage',
        mainEntity: questions.map((question) => getQuestion(question)),
    };
}

function getQuestion({ question, answer }) {
    return {
        '@type': 'Question',
        name: question,
        acceptedAnswer: {
            '@type': 'Answer',
            text: answer,
        },
    };
}

FAQsPageData.propTypes = {
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string.isRequired,
            answer: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default FAQsPageData;
