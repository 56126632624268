import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ContactOurTeam from '../../ContactOurTeam/ContactOurTeam';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import WhyTheHolidayPeople from '../../WhyTheHolidayPeople/WhyTheHolidayPeople';
import styles from './FullWidthTemplate.module.scss';

function FullWidthTemplate({ children, className }) {
    return (
        <div className={styles.fullWidthTemplate}>
            <Header className={styles.header} />
            <main className={className}>{children}</main>
            <ContactOurTeam className={styles.contactUs} />
            <WhyTheHolidayPeople className={styles.whyUs} />
            <Footer className={styles.footer} />
        </div>
    );
}

FullWidthTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default FullWidthTemplate;
