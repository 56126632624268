import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { isExternalUrl } from '../../services/urlService';
import styles from './Tile.module.scss';

const cosmicImgxLoader = ({ src, width, quality }) => {
    return `${src}?h=200&w=${width}&fit=crop&crop=entropy`;
};

function Tile({ as: T = 'li', title, url, imgSrc, className, ...props }) {
    return (
        <T className={classNames(styles.tile, className)} {...props}>
            {isExternalUrl(url) ? (
                getContent(title, url, imgSrc)
            ) : (
                <Link href={url}>{getContent(title, url, imgSrc)}</Link>
            )}
        </T>
    );
}

function getContent(title, url, imgSrc) {
    return (
        <a href={url}>
            <Image
                loader={cosmicImgxLoader}
                className={styles.backgroundImage}
                src={imgSrc}
                alt=""
                layout="fill"
                objectFit="cover"
            />
            <h3>{title}</h3>
        </a>
    );
}

Tile.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Tile;
